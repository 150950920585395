define('m07/components/con-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cone = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'con-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var verschiebung = 0;
      var zVerschiebung = 0;

      if (name === 'axialkraftPfeil' && Number(typ) === -1) {
        verschiebung = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + 1;
        if (x3d.get('zweiSchaerflaechen')) {
          verschiebung = verschiebung + bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).get('z') / 10;
        }
      } else if (name === 'axialkraftPfeil' && Number(typ) === 1) {
        verschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20) - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 - 1;
      } else if (name === 'scherkraftPfeil' && Number(typ) === -1) {
        verschiebung = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 + 3;
        zVerschiebung = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20;
      } else if (name === 'scherkraftPfeil' && Number(typ) === 1) {
        verschiebung = -bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 - 3;
      } else if (name === 'scherkraftPfeil' && Number(typ) === 2) {
        verschiebung = -(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 + 3);
        zVerschiebung = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 10 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;
      }

      var values = '0 ' + verschiebung + ' ' + -zVerschiebung;
      return values;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var winkel = 3.14159;

      if (Number(this.get('typ')) === -1) {
        winkel = 0;
      }

      var values = '1 0 0 ' + winkel;
      return values;
    }).property(''),

    coneTagID: (function () {
      return 'SpitzeLastenpfeil';
    }).property(''),

    coneBottomradius: (function () {
      return '0.075';
    }).property(''),

    coneHeight: (function () {
      return '0.2';
    }).property(''),

    emissivecolor: (function () {
      var x3d = this.get('model').objectAt(0);
      var name = this.get('name');
      var typ = this.get('typ');

      var emsvcolor = '0 0 0';

      if (x3d.get('axialkraftAktiv') === true && name === 'axialkraftPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('scherkraftAktiv') === true && name === 'scherkraftPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.scherkraftAktiv', 'model.firstObject.axialkraftAktiv')

  });

  exports['default'] = Cone;

});