define('m07/components/plz-autocomplete', ['exports', 'ember-cli-auto-complete/components/auto-complete'], function (exports, AutoComplete) {

    'use strict';

    exports['default'] = AutoComplete['default'].extend({
        valueProperty: "code",
        suggestions: function suggestions(options, input) {
            var list = options.filter(function (item) {
                return item.get("code").toLowerCase().indexOf(input.toLowerCase()) > -1;
            });
            return Ember.A(list);
        }
    });

});