define('m07/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),

    arc2dPositionTriggern: DS['default'].attr('boolean', { defaultValue: false }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    dimensionen: DS['default'].hasMany('dimension', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    sFolgeHolzHolz: DS['default'].attr('boolean', { defaultValue: true }),
    zweiSchnittig: DS['default'].attr('boolean', { defaultValue: false }),
    zweiSchaerflaechen: DS['default'].attr('boolean', { defaultValue: false }),

    xMittelbauteil: DS['default'].attr('number'),
    xSeitenbauteil: DS['default'].attr('number'),
    ueberstand: DS['default'].attr('number'),

    setztiefeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    setztiefeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    vWinkelScherkraft: DS['default'].attr('number', { defaultValue: 0 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),

    einschraubwinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    einschraubwinkelEingetragen: DS['default'].attr('boolean', { defaultValue: true }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ntbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ntBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    nthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ntHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    axialkraft: DS['default'].attr('number', { defaultValue: 0 }),
    axialkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    axialkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    winkelscheibe: DS['default'].attr('boolean', { defaultValue: false }),
    scherkraft: DS['default'].attr('number', { defaultValue: 0 }),
    scherkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    scherkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),
    schraubenGekreuzt: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 0.5;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 9 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 9 }),
    ebene: DS['default'].attr('number', { defaultValue: 1 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    xAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    xAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    yAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    yAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstandX: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstandY: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});