define('m07/router', ['exports', 'ember', 'm07/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.reopen({
    location: 'history'
  });

  Router.map(function () {
    this.route('anschlussgeometrie');
    this.route('bauteile');
    this.route('ergebnis');
    this.route('lasteinwirkung');
    this.route('projektdaten');
    this.route('verbindungsmittel');

    this.resource('projektdatens', { path: '/' }, function () {});
    this.route('impressum');
    this.route('printview');
    this.route('contact');
    this.route('downloadcenter');
    this.route('traeger');
    this.route('auflager');
    this.route('geometrie');
    this.route('schraubenausrichtung');
  });

  exports['default'] = Router;

});