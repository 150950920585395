define('m07/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      return values;
    }).property('model.firstObject.transformHelper'),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 0 0';

      var xVerschiebung, yVerschiebung, zVerschiebung;

      switch (name) {
        case 'nebenTraeger':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2;
          zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;
          break;
        case 'hauptTraeger1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          break;
        case 'hauptTraeger2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = -(item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20);
          break;
      }

      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      if (name.substring(0, 8) === 'schraube' || name === 'cylinder') {
        values = '0 0 0 0';
      } else if (name.substring(0, 12) === 'hauptTraeger') {
        var vwinkel = x3d.get('vWinkel') * Math.PI / 180;
        values = '0 0 1 ' + vwinkel;
      } else if (name.substring(0, 5) !== 'plate') {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box' || bauteile.findBy('id', name).get('typ') === 'platte') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    getMassketteHoehe: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0') && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteBreite: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name').substring(0, 12) === 'hauptTraeger' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0') && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteSetztiefe: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && x3d.get('abstaendeAnzeigen') && this.get('name') === 'hauptTraeger1' && Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')) !== 0 && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getscherkraftPfeil: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (this.get('name') === 'hauptTraeger1' || name === 'seitenbauteil0') && x3d.get('scherkraftEingetragen') && !x3d.get('ergebnisGeladen')) {
        value = true;
      }

      return value;
    }).property('model.firstObject.scherkraftEingetragen'),

    istHolz: (function () {
      var value = true;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if ((this.get('name') === 'hauptTraeger1' || this.get('name').substring(0, 13) === 'seitenbauteil') && x3d.get('sFolgeHolzHolz') === false) {
        value = false;
      }

      return value;
    }).property('model.firstObject.transformHelper')
  });

  exports['default'] = Transform;

});