define('m07/components/extrusio-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Extrusion = Ember['default'].Component.extend({
    tagName: 'extrusion',
    attributeBindings: ['endCap', 'solid', 'creaseAngle', 'crosssection', 'orientation', 'scale', 'spine'],
    endCap: true,
    solid: true,
    ispickable: "0",
    creaseAngle: 7,

    //crossection beschreibt die zu extrodierende Grundform in der xz-Ebene
    crosssection: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var values = false;
      var x;
      var y;
      var z;

      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "box") {

        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        var deltaXht = 0;
        var deltaXnt = 0;
        var xMittelbauteil = 0;
        var xSeitenbauteil = 0;

        var ueberstand = Number(x3d.get('ueberstand'));;

        var x1 = 0,
            x2 = 0;

        var schraubenlaenge = Number(bauteile.findBy('id', "schraubeStandard1").get('screwProperties').objectAt(0).get('length')) / 10;
        var kopfPos = schraubenlaenge * Math.sin(0.7854);

        switch (name) {
          case 'nebenTraeger':
            // x = 2.5*bauteile.findBy('id', 'queranschluss1').get('boxsizes').objectAt(0).get('x')/10;
            deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
            xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
            break;
          case 'hauptTraeger1':
            deltaXht = kopfPos + ueberstand;
            xSeitenbauteil = Math.abs(x3d.get('xSeitenbauteil') / 10);
            break;
          case 'hauptTraeger2':
            // deltaXht = 1.5*Math.abs(x3d.get('xSeitenbauteil')/10) + ueberstand;
            deltaXht = kopfPos + ueberstand;
            xSeitenbauteil = Math.abs(x3d.get('xSeitenbauteil') / 10);
            break;
        }

        values = '[(' + (-deltaXnt - xSeitenbauteil) + ',' + -z / 2 + '),(' + (-deltaXnt - xSeitenbauteil) + ',' + z / 2 + '),(' + (deltaXht + xMittelbauteil) + ',' + z / 2 + '),(' + (deltaXht + xMittelbauteil) + ',' + -z / 2 + '),(' + (-deltaXnt - xSeitenbauteil) + ',' + -z / 2 + ')]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //orientation beschreibt die Richtung in der die Extrusion erfolgt
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Richtung für jede Stufe anzugeben.
    orientation: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');

      var values = '0 1 0 0, 0 1 0 0';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(0,0,0,1)]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //scale beschreibt die Skalierung der Grundfläche während eines Extrusionschritts
    //Wird die Extrusion in mehreren Schritten/Stufen durchgeführt, ist die Skalierung für jeden Schritt/Stufe anzugeben.
    scale: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');

      var values = '0 0, 0.025 0.025, 0.05 0.05, 0.07 0.07';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        values = '[(1,1)]';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    //spine beschreibt die Länge der Extrusion in y-Richtung
    //Die Extrusion kann in mehreren Schritten/Stufen erfolgen
    spine: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }
      var x3ddefault = this.get('model');
      var bauteile = x3ddefault.objectAt(0).get('bauteile');
      var values = '0 0 0';

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === "box") {
        var y = item.get('boxsizes').objectAt(0).get('y') / 10;
        values = '[(0,0,0), (0 ' + y + ' 0)]';
      }

      return values;
    }).property('model.firstObject.transformHelper')
  });

  exports['default'] = Extrusion;

});