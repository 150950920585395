define('m07/models/bauteile', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var bauteile = DS['default'].Model.extend({

		MatKeyTimberElement1: DS['default'].attr('string'),
		FKLKeyTimberElement1: DS['default'].attr('string'),
		TimberElement1_b: DS['default'].attr('string'),
		TimberElement1_h: DS['default'].attr('string'),
		MatKeyTimberElement2: DS['default'].attr('string'),
		FKLKeyTimberElement2: DS['default'].attr('string'),
		TimberElement2_b: DS['default'].attr('string'),
		TimberElement2_h: DS['default'].attr('string'),
		ALFA_Elements_GRAD: DS['default'].attr('string'),
		CorrosionResistanceNecessary: DS['default'].attr('boolean'),
		MatKeyInterlayer: DS['default'].attr('string'),
		FKLKeyInterlayer: DS['default'].attr('string'),
		materialbezeichnung: DS['default'].attr('string'),

		TimberElement1Predrilled: DS['default'].attr('boolean'),
		TimberElement2Predrilled: DS['default'].attr('boolean'),

		TE1_alphaRAD_KraftFaser: DS['default'].attr('string'),
		TE1_alphaRAD_vmFaser: DS['default'].attr('string'),
		TE1_betaRAD_vmFurnier: DS['default'].attr('string'),

		TE2_alphaRAD_KraftFaser: DS['default'].attr('string'),
		TE2_alphaRAD_vmFaser: DS['default'].attr('string'),
		TE2_betaRAD_vmFurnier: DS['default'].attr('string')

	});

	bauteile.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyTimberElement1: "0",
			FKLKeyTimberElement1: "5",
			TimberElement1_b: "0.0",
			TimberElement1_h: "0.0",
			MatKeyTimberElement2: "0",
			FKLKeyTimberElement2: "5",
			TimberElement2_b: "0.0",
			TimberElement2_h: "0.0",
			ALFA_Elements_GRAD: "0",
			CorrosionResistanceNecessary: false,
			MatKeyInterlayer: "0",
			FKLKeyInterlayer: "5",
			materialbezeichnung: "",

			TimberElement1Predrilled: false,
			TimberElement2Predrilled: false,

			TE1_alphaRAD_KraftFaser: "0.0",
			TE1_alphaRAD_vmFaser: "45.0",
			TE1_betaRAD_vmFurnier: "45.0",

			TE2_alphaRAD_KraftFaser: "0.0",
			TE2_alphaRAD_vmFaser: "45.0",
			TE2_betaRAD_vmFurnier: "45.0"
		}]
	});

	exports['default'] = bauteile;

	// bauteile.reopenClass({
	// 	FIXTURES: [
	// 		{
	// 			id: 1,
	// 			MatKeyTimberElementHT: "0",
	// 			FKLKeyTimberElementHT: "5",
	// 			TimberElementHT_b: "20",
	// 			TimberElementHT_h: "20",
	// 			MatKeyTimberElementNT: "0",
	// 			FKLKeyTimberElementNT: "5",
	// 			TimberElementNT_b: "20",
	// 			TimberElementNT_h: "20",
	// 		}
	// 	]
	// });

});