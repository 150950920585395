define('m07/models/bauteil', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    typ: DS['default'].attr('string'),
    boxsizes: DS['default'].hasMany('boxsize', { async: false }),
    appearances: DS['default'].hasMany('appearance', { async: false }),
    translations: DS['default'].hasMany('translation', { async: false }),
    rotations: DS['default'].hasMany('rotation', { async: false }),
    diffusecolors: DS['default'].hasMany('diffusecolor', { async: false }),
    orientations: DS['default'].hasMany('orientation', { async: false }),
    positions: DS['default'].hasMany('position', { async: false }),
    screwProperties: DS['default'].hasMany('screwProperty', { async: false }),
    cameraProperties: DS['default'].hasMany('cameraProperty', { async: false }),

    punkt: DS['default'].hasMany('punkt', { async: false }),
    richtungsvektor: DS['default'].hasMany('richtungsvektor', { async: false }),
    ansichtsvektoren: DS['default'].hasMany('ansichtsvektoren', { async: false }),
    orthovektor: DS['default'].hasMany('orthovektor', { async: false }),
    platte: DS['default'].hasMany('platte', { async: false }),
    masskette: DS['default'].hasMany('masskette', { async: false }),
    lagewinkel: DS['default'].hasMany('lagewinkel', { async: false })

  });

});