define('m07/controllers/geometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

        displayErrors: true,
        openFromFile: false,
        stahlholz: false,
        sflaechendisabled: false,
        scherflaechenA: 1,
        scherflaechenVM: 1,
        schichtfolge: 1,
        setztiefe: '0.0',

        ttSetztiefe: Ember['default'].computed('i18n.locale', function () {

            return this.get('i18n').t('wertebereich') + " 0.0 - 5.0 [cm]";
        }),

        i18n: Ember['default'].inject.service(),

        validations: {
            setztiefe: {
                numericality: {
                    greaterThanOrEqualTo: 0.0,
                    lessThanOrEqualTo: 5.0
                }
            }
        },

        sflaechenVM: [],

        sflaechenA: [],

        sfolge: [],

        vgebohrt: [],

        init: function init() {

            this._super();

            this.set('setztiefe', "0.0");

            this.setSelectFieldsContent();
        },

        setfuehrendeNull: function setfuehrendeNull(ind) {
            if (this.get(ind).charAt(0) === '.') {
                this.set(ind, '0.');
            }
        },

        setValues: function setValues(values) {

            var self = this;

            if (self.debug) {
                console.log("values in bauteile: ");
                console.log(values);
            }

            var setztiefeEvent = {
                target: {
                    name: "setztiefe"
                }
            };
            //this.setX3D(parseInt(values.Setztiefe), setztiefeEvent);

            this.set('openFromFile', true);

            this.set('scherflaechenVM', parseInt(values.scherflaechenVM));
            this.set('scherflaechenA', parseInt(values.scherflaechenA));
            this.set('schichtfolge', parseInt(values.schichtfolge));
            this.set('vorgebohrt', values.Predrilled);
            this.set('setztiefe', values.Setztiefe);

            this.send('validation');

            this.set('openFromFile', false);
        },

        setSelectFieldsContent: function setSelectFieldsContent() {
            var self = this;
            self.setSflaechenVM();
            self.setSflaechenA();
            self.setSfolge();
            self.setVgebohrt();
            self.setKWinkel();
            self.setZschichtVerstaerkung();
        },

        setSflaechenVM: function setSflaechenVM() {
            var self = this;
            var indices = [1, 2];
            self.set('sflaechenVM', self.getSelectFieldContent('sflaechenVM', indices));
        },

        setSflaechenA: function setSflaechenA() {
            var self = this;
            var indices = [1, 2];
            self.set('sflaechenA', self.getSelectFieldContent('sflaechenA', indices));
        },

        setSfolge: function setSfolge() {
            var self = this;
            var indices = [1, 2];
            self.set('sfolge', self.getSelectFieldContent('sfolge', indices));
        },

        setVgebohrt: function setVgebohrt() {
            var self = this;
            var indices = [false, true];
            self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
        },

        setKWinkel: function setKWinkel() {
            var self = this;
            var indices = [true, false];
            self.set('kWinkel', self.getSelectFieldContent('kWinkel', indices));
        },

        setZschichtVerstaerkung: function setZschichtVerstaerkung() {
            var self = this;
            var indices = [0, 1, 2];
            self.set('zschichtVerstaerkung', self.getSelectFieldContent('zschichtVerstaerkung', indices));
        },

        getSelectFieldContent: function getSelectFieldContent(db, indexes) {
            var self = this;
            var tmp = [];
            var dbContent = self.getDBContent(db);
            for (var i = 0; i < indexes.length; i++) {
                for (var k = 0; k < dbContent.length; k++) {
                    if (dbContent[k].id === indexes[i]) {
                        tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
                    }
                }
            }

            return tmp;
        },

        getDBContent: function getDBContent(db) {
            var dbContent = [];
            switch (db) {
                case 'sflaechenVM':
                    dbContent = scherflaechenDB.sflaechenVM;
                    break;
                case 'sflaechenA':
                    dbContent = scherflaechenDB.sflaechenA;
                    break;
                case 'sfolge':
                    dbContent = scherflaechenDB.sfolge;
                    break;
                case 'vgebohrt':
                    dbContent = scherflaechenDB.vgebohrt;
                    break;
                case 'kWinkel':
                    dbContent = scherflaechenDB.kWinkel;
                    break;
                case 'zschichtVerstaerkung':
                    dbContent = scherflaechenDB.zschichtVerstaerkung;
                    break;
            }
            return dbContent;
        },

        getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
            var self = this;

            var tmp = "";
            var len = contentArray.length;

            for (var i = 0; i < len; i++) {
                if (contentArray[i].id === index) {
                    tmp = contentArray[i].name;
                }
            }
            return tmp;
        },

        watchNumericalInputs: (function () {

            if (!this.openFromFile) {

                if (this.get('setztiefe').indexOf(",") !== -1) {
                    this.set('setztiefe', this.get('setztiefe').replace(",", "."));
                    this.setfuehrendeNull('setztiefe');
                }
            }
        }).observes('setztiefe'),

        watchComboboxes: (function () {

            var self = this;

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var application = self.controllerFor('application');
            application.set('verbindungsmittelInvalid', true);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

            // var auflager = this.controllerFor('application').get('model').auflager.objectAt(0);

            // auflager.set('LagerTyp', this.get('aTyp'));
            // auflager.set('LagerText', this.get('aTyp'));

            var lasteinwirkung = self.controllerFor('lasteinwirkung');
            var bauteile = self.controllerFor('bauteile');
            var schraubenausrichtung = self.controllerFor('schraubenausrichtung');

            if (self.get('schichtfolge') == 2) {
                self.set('stahlholz', true);
                lasteinwirkung.set('stahl', true);
                application.set('stahl', true);
                bauteile.set('stahl', true);
                // bauteile.init();
                // self.set('setztiefe', '0.0');
                x3d.set('sFolgeHolzHolz', false);

                if (self.get('scherflaechenVM') === 2) {
                    bauteile.set('htStahl', false);
                    bauteile.set('ntStahl', true);
                } else {
                    bauteile.set('ntStahl', false);
                    bauteile.set('htStahl', true);
                }
            } else {
                self.set('stahlholz', false);
                lasteinwirkung.set('stahl', false);
                application.set('stahl', false);
                bauteile.set('stahl', false);
                x3d.set('sFolgeHolzHolz', true);
                bauteile.set('htStahl', false);
                bauteile.set('ntStahl', false);
            }

            if (self.get('scherflaechenVM') == 2) {
                self.set('scherflaechenA', 2);
                self.set('sflaechendisabled', true);
                self.set('schichtfolge', 1);
                self.set('schichtfolgedisabled', true);
                x3d.set('zweiSchnittig', true);
                x3d.set('zweiSchaerflaechen', true);
            } else {
                self.set('sflaechendisabled', false);
                self.set('schichtfolgedisabled', false);
                x3d.set('zweiSchnittig', false);
            }

            if (self.get('scherflaechenA') === 2) {
                x3d.set('zweiSchaerflaechen', true);
            } else {
                x3d.set('zweiSchaerflaechen', false);
            }

            self.schnittigkeitSetzen();
            self.setSchraubenlaenge();

            var schraubenausrichtung = this.controllerFor('schraubenausrichtung');
            schraubenausrichtung.watchSchraubenausrichtung();
            var geometrie = this.controllerFor('application').get('model').geometrie.objectAt(0);
            geometrie.set('Predrilled', self.get('vorgebohrt'));

            geometrie.set('scherflaechenA', self.get('scherflaechenA'));
            geometrie.set('scherflaechenVM', self.get('scherflaechenVM'));
            geometrie.set('schichtfolge', self.get('schichtfolge'));

            x3d.set('ergebnisGeladen', false);
            x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
            x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        }).observes('schichtfolge', 'scherflaechenVM', 'scherflaechenA', 'vorgebohrt'),

        schnittigkeitSetzen: function schnittigkeitSetzen() {

            var self = this;

            var sfvm = self.get('scherflaechenVM');
            var sfa = self.get('scherflaechenA');
            var sf = self.get('schichtfolge');

            var geometrieData = this.controllerFor('application').get('model').geometrie.objectAt(0);
            var schraubenausrichtungData = this.controllerFor('application').get('model').schraubenausrichtung.objectAt(0);
            var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

            var bauteile = this.controllerFor('bauteile');
            var schraubenausrichtung = this.controllerFor('schraubenausrichtung');

            console.log('schraubenausrichtungen');
            console.log(schraubenausrichtung.get('schraubenausrichtungen'));
            console.log();

            if (sfvm === 1 && sfa === 1 && sf === 1) {
                console.log("1 + HH");
                geometrieData.set('ShearPlaneMultiplicator', '1');
                geometrieData.set('ConnectionType', 'HH');
                bauteile.set('materialienOption', 1);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 0);
                bauteile.set('htfkl', 5);
                bauteile.set('ntholz', 0);
                bauteile.set('ntfkl', 5);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = false;
                schraubenausrichtung.set('winkelscheibeDisabled', true);
                schraubenausrichtung.set('winkelscheibe', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
                this.setHTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
            } else if (sfvm === 1 && sfa === 1 && sf === 2) {
                console.log("1 + HS");
                geometrieData.set('ShearPlaneMultiplicator', '1');
                geometrieData.set('ConnectionType', 'SH');
                bauteile.set('materialienOption', 2);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 1000);
                bauteile.set('htfkl', 23.5);
                bauteile.set('ntholz', 0);
                bauteile.set('ntfkl', 5);
                schraubenausrichtung.set('schraubenausrichtung', false);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = true;
                schraubenausrichtung.set('winkelscheibeDisabled', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(2, {
                    rot: 0.6,
                    gruen: 0.8,
                    blau: 0.9
                });
                this.setHTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
            } else if (sfvm === 1 && sfa === 2 && sf === 1) {
                console.log("2 + HH");
                geometrieData.set('ShearPlaneMultiplicator', '2');
                geometrieData.set('ConnectionType', 'HH');
                bauteile.set('materialienOption', 3);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 0);
                bauteile.set('htfkl', 5);
                bauteile.set('ntholz', 0);
                bauteile.set('ntfkl', 5);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = false;
                schraubenausrichtung.set('winkelscheibeDisabled', true);
                schraubenausrichtung.set('winkelscheibe', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
                this.setHTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
            } else if (sfvm === 2 && sfa === 2 && sf === 1) {
                console.log("1 + HHH");
                geometrieData.set('ShearPlaneMultiplicator', '1');
                geometrieData.set('ConnectionType', 'HHH');
                bauteile.set('materialienOption', 4);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 0);
                bauteile.set('htfkl', 5);
                bauteile.set('ntholz', 0);
                bauteile.set('ntfkl', 5);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = false;
                schraubenausrichtung.set('winkelscheibeDisabled', true);
                schraubenausrichtung.set('winkelscheibe', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
                this.setHTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
            } else if (sfvm === 1 && sfa === 2 && sf === 2) {
                console.log("2 + SH");
                geometrieData.set('ShearPlaneMultiplicator', '2');
                geometrieData.set('ConnectionType', 'SH');
                bauteile.set('materialienOption', 5);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 1000);
                bauteile.set('htfkl', 23.5);
                bauteile.set('ntholz', 0);
                bauteile.set('ntfkl', 5);
                schraubenausrichtung.set('schraubenausrichtung', false);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = true;
                schraubenausrichtung.set('winkelscheibeDisabled', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(2, {
                    rot: 0.6,
                    gruen: 0.8,
                    blau: 0.9
                });
                this.setHTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
            } else if (sfvm === 2 && sfa === 2 && sf === 2) {
                console.log("1 + HSH");
                geometrieData.set('ShearPlaneMultiplicator', '1');
                geometrieData.set('ConnectionType', 'HSH');
                bauteile.set('materialienOption', 6);
                bauteile.setHtMaterialien();
                bauteile.set('htholz', 0);
                bauteile.set('htfkl', 5);
                bauteile.set('ntholz', 1000);
                bauteile.set('ntfkl', 24);
                schraubenausrichtung.set('schraubenausrichtung', false);
                schraubenausrichtung.get('schraubenausrichtungen')[1].disabled = true;
                schraubenausrichtung.set('winkelscheibeDisabled', false);
                schraubenausrichtung.setSelectFieldsContent();
                this.setNTgrafik(10, {
                    rot: 0.52,
                    gruen: 0.385,
                    blau: 0.02
                });
                this.setHTgrafik(2, {
                    rot: 0.6,
                    gruen: 0.8,
                    blau: 0.9
                });
            }
        },

        setSchraubenlaenge: function setSchraubenlaenge() {
            var self = this;

            var application = this.controllerFor('application');
            var x3d = application.get('model').x3ddefault.objectAt(0);
            var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

            var ntBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
            var htBreite = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z');

            var schraubenLaenge = 0,
                hoeheWinkelscheibe = 0;

            if (x3d.get('winkelscheibe') === true) {
                hoeheWinkelscheibe = Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('headdiameter')) + 0.4;
            }

            if (x3d.get('zweiSchnittig')) {
                schraubenLaenge = (Number(htBreite) + Number(ntBreite) + Number(htBreite) / 2 - Number(self.get('setztiefe'))) / Math.cos(Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y')));
                var z = 0 - (Number(htBreite) + Number(ntBreite)) / 2;
                bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', z);
            } else {
                schraubenLaenge = hoeheWinkelscheibe + (Number(htBreite) + Number(ntBreite) / 2 - Number(self.get('setztiefe'))) / Math.cos(Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y')));
                bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', 0);
            }

            bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
            bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
            bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
            bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);

            x3d.set('transformHelper', !x3d.get('transformHelper'));
            x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        },

        setNTgrafik: function setNTgrafik(breite, farbe) {
            var application = this.controllerFor('application');
            var x3d = application.get('model').x3ddefault.objectAt(0);
            var bauteile = x3d.get('bauteile');
            var ht1 = bauteile.findBy('id', 'hauptTraeger1');
            var ht2 = bauteile.findBy('id', 'hauptTraeger2');

            if (!x3d.get('htBreiteEingetragen')) {
                ht1.get('boxsizes').objectAt(0).set('z', breite);
                ht2.get('boxsizes').objectAt(0).set('z', breite);
            }

            ht1.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
            ht1.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
            ht1.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

            ht2.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
            ht2.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
            ht2.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

            x3d.set('transformHelper', !this.get('transformHelper'));
        },

        setHTgrafik: function setHTgrafik(breite, farbe) {
            var application = this.controllerFor('application');
            var x3d = application.get('model').x3ddefault.objectAt(0);
            var bauteile = x3d.get('bauteile');
            var nt = bauteile.findBy('id', 'nebenTraeger');

            if (!x3d.get('ntBreiteEingetragen')) {
                nt.get('boxsizes').objectAt(0).set('z', breite);
            }

            nt.get('diffusecolors').objectAt(0).set('rot', farbe.rot);
            nt.get('diffusecolors').objectAt(0).set('gruen', farbe.gruen);
            nt.get('diffusecolors').objectAt(0).set('blau', farbe.blau);

            x3d.set('transformHelper', !this.get('transformHelper'));
        },

        setX3D: function setX3D(value, event) {

            var application = this.controllerFor('application');
            var x3d = application.get('model').x3ddefault.objectAt(0);
            var bauteile = x3d.get('bauteile');

            var self = this;

            x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
            x3d.set('ergebnisGeladen', false);
            x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

            value = parseFloat(value).toFixed(1);

            switch (event.target.name) {
                case 'setztiefe':
                    if (this.errors.setztiefe.length === 0) {
                        bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('setztiefe', value);
                        bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('setztiefe', value);
                        x3d.set('setztiefeEingetragen', true);
                        self.setSchraubenlaenge();
                    } else {
                        x3d.set('setztiefeEingetragen', false);
                    }
                    break;
            }

            x3d.set('transformHelper', !x3d.get('transformHelper'));
            self.controllerFor('application').zentriertObjekt();
        },

        actions: {

            validation: function validation(value, event) {

                var self = this;

                if (!self.openFromFile) {

                    this.setX3D(value, event);
                }

                self.validate().then(function () {
                    // all validations pass

                })['catch'](function () {
                    // any validations fail

                })['finally'](function () {

                    var validation = self.get('isValid');
                    var application = self.controllerFor('application');
                    if (validation === true) {
                        self.set('displayErrors', false);
                        application.set('geometrieInvalid', false);

                        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
                        applicationdata.set('treeLoaded', false);
                        applicationdata.set('initialized', false);
                        applicationdata.set('pdfErstellt', false);
                        applicationdata.set('dxfErstellt', false);

                        var downloadcenter = self.controllerFor('downloadcenter');
                        downloadcenter.set('pdfErstellt', false);
                        downloadcenter.set('dxfErstellt', false);

                        application.set('verbindungsmittelInvalid', true);

                        var geometrie = self.controllerFor('application').get('model').geometrie.objectAt(0);
                        geometrie.set('Setztiefe', self.get('setztiefe'));
                    } else {
                        self.set('displayErrors', true);
                        application.set('geometrieInvalid', true);
                    }
                });
            },

            setztiefeIsSelected: function setztiefeIsSelected() {
                var setztiefeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('setztiefeAktiv');
                this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('setztiefeAktiv', !setztiefeAktiv);

                if (this.get('setztiefe') !== "") {
                    var setztiefework = parseFloat(this.get('setztiefe').replace(",", "."));
                    var setztieferounded = setztiefework.toFixed(1);
                    this.set('setztiefe', setztieferounded);
                    this.send('validation', setztieferounded, { target: { name: "setztiefe" } });
                }

                if (setztiefeAktiv === false) {
                    document.getElementsByName('setztiefe')[0].setSelectionRange(0, this.get('setztiefe').length);
                }
            }
        }
    });

});