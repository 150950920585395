define('m07/components/arctwo-d', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'arctwo-d',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var cords = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0;

      switch (name) {
        case 'einschraubwinkel':
          xVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20;
          yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;
          zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20;
          break;
        case 'vWinkel':
          xVerschiebung = 0;
          yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;
          zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20 + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 20;
          break;
        case 'winkelscheibe':
          xVerschiebung = 0;
          yVerschiebung = 0;
          zVerschiebung = 0;
          break;
      }

      cords = xVerschiebung + ' ' + yVerschiebung + ' ' + zVerschiebung;

      return cords;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkel', 'model.firstObject.vWinkel'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var values = '0 0 0 0';
      if (name === 'einschraubwinkel') {
        values = '1 0 0 -1.5707';
      }

      switch (name) {
        case 'einschraubwinkel':
          values = '1 0 0 -1.5707';
          break;
        case 'winkelscheibe':
          values = '1 0 0 -1.5707';
          break;
      }

      return values;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkel', 'model.firstObject.vWinkel'),

    anfangsWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;
      var winkel = Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y'));

      switch (name) {
        case 'einschraubwinkel':
          value = -0.7854;
          break;
        case 'vWinekl':
          value = 0;
          break;
        case 'winkelscheibe':
          value = 0;
          break;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkel', 'model.firstObject.vWinkel'),

    endWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;

      switch (name) {
        case 'einschraubwinkel':
          value = 0;
          break;
        case 'vWinkel':
          value = Number(x3d.get('vWinkel')) * Math.PI / 180 + 0.0001;
          break;
        case 'winkelscheibe':
          value = 2 * Math.PI;
          break;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkel', 'model.firstObject.vWinkel'),

    radius: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;

      switch (name) {
        case 'einschraubwinkel':
          value = 1.5;
          break;
        case 'vWinkel':
          var l_endLinien = 0.5;
          var ueberstand = Number(x3d.get('ueberstand')) + 1 + Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
          value = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + ueberstand + l_endLinien / 2;
          break;
        case 'winkelscheibe':
          var typ = this.get('typ');
          var item = bauteile.findBy('id', typ);

          value = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) * 0.15;
          break;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkel', 'model.firstObject.einschraubwinkelAktiv'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('einschraubwinkelAktiv') === true && name === 'einschraubwinkel' && typ === 'einschraubwinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'vWinkel' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.einschraubwinkelAktiv', 'model.firstObject.vWinkelAktiv'),

    winkelscheibe: (function () {
      var name = this.get('name');
      var value = false;

      if (name === 'winkelscheibe') {
        value = true;
      }

      return value;
    }).property('')
  });

  exports['default'] = Transform;

});