define('m07/components/shap-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Shape = Ember['default'].Component.extend({
    tagName: 'shape',
    layoutName: 'shap-e',
    attributeBindings: ['bboxcenter', 'bboxsize', 'ispickable', 'DEF', 'USE'],
    bboxcenter: "0,0,0",
    bboxsize: "-1,-1,-1",
    ispickable: "0",

    DEF: (function () {
      var name = this.get('name');

      var value = '';

      if (name === 'schraube') {
        value = 'schraube';
      }
      return value;
    }).property(''),

    // TODO: besser DEF USE für die Schrauben verwenden. Bislang noch keine Lösung gefunden
    // USE: function () {
    //   var name = this.get('name');
    //
    //   var value = '';
    //
    //   if (name === 'schraube2'){
    //     value = 'schraube';
    //   }
    //   return value;
    //
    // }.property(''),

    istExtrusion: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var istextrusion = false;

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "box" || item.get('typ') === "verbindungsmittel") {
        istextrusion = true;
      }

      return istextrusion;
    }).property(''),

    istSchraube: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);

      var istSchraube = false;

      if (name.substring(0, 8) === 'schraube' || item.get('typ') === 'verbindungsmittel') {
        istSchraube = true;
      }

      return istSchraube;
    }).property(''),

    istFaceset: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var istFaceset = false;

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === "platte") {
        istFaceset = true;
      }

      return istFaceset;
    }).property('')

  });

  exports['default'] = Shape;

});