define('m07/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    yDeltaOben: 0,
    yDeltaUnten: 0,

    translation: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords = '0 0 0';

      var item = x3d.get('bauteile').findBy('id', name);

      if (typ === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');

        if (x3d.get('ergebnisGeladen') === true) {
          y = 0;
        } else {
          y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
        }

        if (name === 'scherkraftPfeil') {
          if (x3d.get('ergebnisGeladen') === true) {
            z = Number(bauteile.findBy('id', 'mittelbauteil').get('platte').objectAt(0).get('thickness')) / 20 + Number(bauteile.findBy('id', 'seitenbauteil0').get('platte').objectAt(0).get('thickness')) / 20;
          } else {
            z = 0;
          }
        } else {
          z = item.get('translations').objectAt(0).get('z');
        }

        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'lastenPfeil') {
        var winkel = item.get('rotations').objectAt(0).get('winkel');

        if (name === 'scherkraftPfeil') {
          winkel = Number(winkel) + Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;
        }

        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + winkel;
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      // var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var cords = '';

      var p1 = new Object();
      var p2 = new Object();

      var drehwinkel = new Object();

      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      var ueberstand = Number(x3d.get('ueberstand'));;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;

      if (item.get('typ') === 'box') {

        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (typ === 'kontur') {

          cords = this.getKonturKoordinaten(x, y, z);
        }

        if (x3d.get('ergebnisGeladen') === false && typ === 'faserrichtung' && (name.substring(0, 12) === 'hauptTraeger' || name === 'nebenTraeger')) {
          cords = this.getFaserrichtungsPfeile(item);
        }

        switch (typ) {
          case 'breite':

            var xVerschiebung = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            var yVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            if (name === 'nebenTraeger') {
              xVerschiebung = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
            }

            p1.x = xVerschiebung;
            p1.y = yVerschiebung;
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = xVerschiebung;
            p2.y = yVerschiebung;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = 0;
            }
            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;
          case 'hoehe':
            var xVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
            var zVerschiebung = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (name === 'nebenTraeger') {
              p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -zVerschiebung;

              p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
              p2.y = 0;
              p2.z = -zVerschiebung;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                if (x3d.get('zweiSchaerflaechen') === true && name === 'nebenTraeger') {
                  p1.z = p1.z - Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;
                  p2.z = p2.z - Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;
                }

                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
            } else if (name === 'hauptTraeger1') {

              var schraubenlaenge = Number(x3d.get('bauteile').findBy('id', "schraubeStandard1").get('screwProperties').objectAt(0).get('length')) / 10;
              var kopfPos = schraubenlaenge * Math.sin(0.7854);

              p1.x = kopfPos + ueberstand;
              p1.y = 0;
              p1.z = zVerschiebung;

              p2.x = kopfPos + ueberstand;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.z = zVerschiebung;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;
          case 'setztiefe':

            p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            p1.y = 0;
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            p2.y = 0;
            p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe') / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              p1.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - hoeheMasskette - abstandVomBauteil;
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p2.x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - hoeheMasskette - abstandVomBauteil;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = -w180Grad;
            }
            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;
        }
      }

      if (x3d.get('ergebnisGeladen') && item.get('typ') === 'platte') {

        switch (typ) {
          case 'kontur':
            cords = this.getErgebnisKonturKoordinaten();
            break;
          case 'faserrichtung':
            cords = this.getFaserrichtungsPfeile(item);
            break;
          case 'breite':

            p2.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
            p2.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;
            p2.z = Number(item.get('punkt').objectAt(0).get('z')) / 10;

            p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
            p1.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;
            p1.z = Number(item.get('punkt').objectAt(0).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = 0;
            }
            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);

            break;
          case 'hoehe':

            if (name === 'mittelbauteil') {
              p2.y = Number(item.get('punkt').objectAt(1).get('y')) / 10;
              p1.y = Number(item.get('punkt').objectAt(0).get('y')) / 10;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p2.x = Number(item.get('punkt').objectAt(1).get('x')) / 10;
                p2.z = Number(item.get('punkt').objectAt(1).get('z')) / 10 - abstandVomBauteil - hoeheMasskette;

                p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
                p1.z = Number(item.get('punkt').objectAt(0).get('z')) / 10 - abstandVomBauteil - hoeheMasskette;

                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else {
                p2.x = Number(item.get('punkt').objectAt(1).get('x')) / 10 - abstandVomBauteil - hoeheMasskette;
                p2.z = Number(item.get('punkt').objectAt(1).get('z')) / 10;

                p1.x = Number(item.get('punkt').objectAt(0).get('x')) / 10 - abstandVomBauteil - hoeheMasskette;
                p1.z = Number(item.get('punkt').objectAt(0).get('z')) / 10;

                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
            } else if (name === 'seitenbauteil0') {

              p1.x = Number(item.get('punkt').objectAt(2).get('x')) / 10;
              p1.y = Number(item.get('punkt').objectAt(2).get('y')) / 10;
              p1.z = Number(item.get('punkt').objectAt(2).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;

              p2.x = Number(item.get('punkt').objectAt(3).get('x')) / 10;
              p2.y = Number(item.get('punkt').objectAt(3).get('y')) / 10;
              p2.z = Number(item.get('punkt').objectAt(3).get('z')) / 10 + Number(item.get('platte').objectAt(0).get('thickness')) / 10;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else {
                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
            break;

        }
      }

      if (name === typ) {

        p1.x = item.get('punkt').objectAt(1).get('x') / 10;
        p1.y = item.get('punkt').objectAt(1).get('y') / 10;
        p1.z = item.get('punkt').objectAt(1).get('z') / 10;

        p2.x = item.get('punkt').objectAt(0).get('x') / 10;
        p2.y = item.get('punkt').objectAt(0).get('y') / 10;
        p2.z = item.get('punkt').objectAt(0).get('z') / 10;

        var richtungsvektor1 = new Object();
        var richtungsvektor2 = new Object();

        var sichtEbene = "AnsichtXY";
        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          sichtEbene = "AnsichtXZ";
        } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          sichtEbene = "AnsichtYZ";
        }

        sichtEbene = 'masskette' + sichtEbene + item.id.substring(0, item.id.length - 3);

        richtungsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
        richtungsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
        richtungsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));

        richtungsvektor2 = richtungsvektor1;

        var ebene = Number(item.get('masskette').objectAt(0).get('ebene'));

        cords = this.getVektorKoordinaten(p1, p2, richtungsvektor1, richtungsvektor2, ebene);
      }

      if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && x3d.get('ergebnisGeladen')) {

        var xVerschiebungSeitenbauteil = Math.abs(x3d.get('xSeitenbauteil') / 10);
        var xVerschiebungMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);

        var yMittelbauteil = x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
        var ySeitenbauteil = x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');

        var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        var zVerschiebung = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;

        var schraubenlaenge = (item.get('screwProperties').objectAt(0).get('length') + item.get('screwProperties').objectAt(0).get('headheight')) / 10;
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (typ === 'yPosMittelbauteil') {

          bauteile.forEach(function (bauteil) {
            if (bauteil.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
              if (Number(item.get('translations').objectAt(0).get('y')) > Number(bauteil.get('translations').objectAt(0).get('y'))) vorgaenger = Number(bauteil.get('translations').objectAt(0).get('y')) / 10;
              return;
            }
          });

          if (item) {
            p1.x = -xVerschiebungSeitenbauteil - ueberstand - abstandVomBauteil - hoeheMasskette;
            p2.x = -xVerschiebungSeitenbauteil - ueberstand - abstandVomBauteil - hoeheMasskette;
            p1.z = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20);
            p2.z = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = 0;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            }
          } else {
            p1.x = xVerschiebungMittelbauteil + ueberstand - abstandVomBauteil - hoeheMasskette;
            p2.x = xVerschiebungMittelbauteil + ueberstand - abstandVomBauteil - hoeheMasskette;
            p1.z = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20;
            p2.z = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20;;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = 0;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = -w90Grad;
            }
          }

          p1.y = vorgaenger;
          p2.y = item.get('translations').objectAt(0).get('y') / 10;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else {
            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = w90Grad;
          }
        }

        if (typ === 'zPos') {

          var zPos = Number(item.get('translations').objectAt(0).get('z'));
          var vorgaenger = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          bauteile.forEach(function (bauteil) {
            if (bauteil.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
              if (Number(item.get('translations').objectAt(0).get('z')) > Number(bauteil.get('translations').objectAt(0).get('z'))) vorgaenger = Number(bauteil.get('translations').objectAt(0).get('z')) / 10;
            }
          });

          p1.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
          p1.y = 0;
          p1.z = vorgaenger;

          p2.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
          p2.y = 0;
          p2.z = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('z')) / 10;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = -w90Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          } else {
            drehwinkel.x = w180Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          }
        }

        if (typ === 'zMax') {

          p1.x = Number(item.get('translations').objectAt(0).get('x')) / 10;
          p1.y = 0;
          p1.z = Number(item.get('translations').objectAt(0).get('z')) / 10;;

          p2.x = Number(item.get('translations').objectAt(0).get('x')) / 10;
          p2.y = 0;
          p2.z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            drehwinkel.x = -w90Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          } else {
            drehwinkel.x = w180Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          }
        }

        cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
      }

      if (typ === 'lastenPfeil') {

        var verschiebung1 = 0;
        var verschiebung2 = 0;
        var verschiebung3 = 0;
        if (name === 'axialkraftPfeil') {
          verschiebung1 = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          if (x3d.get('zweiSchaerflaechen')) {
            verschiebung1 = verschiebung1 + bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).get('z') / 10;
          }
          verschiebung2 = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;
        } else if (name === 'scherkraftPfeil') {
          verschiebung1 = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 + 2;
          verschiebung2 = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 + 2;
          verschiebung3 = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20;
        }

        if (x3d.get('zweiSchaerflaechen')) {
          cords = '0 ' + 0 + ' ' + -verschiebung3 + ' 0 ' + (verschiebung1 + 1) + ' ' + -verschiebung3 + ' 0 ' + 0 + ' 0 0 ' + (-verschiebung2 - 1) + ' 0 0 ' + 0 + ' ' + -2 * verschiebung3 + ' 0 ' + (-verschiebung2 - 1) + ' ' + -2 * verschiebung3;
        } else {
          cords = '0 ' + 0 + ' ' + -verschiebung3 + ' 0 ' + (verschiebung1 + 1) + ' ' + -verschiebung3 + ' 0 ' + 0 + ' 0 0 ' + (-verschiebung2 - 1) + ' 0';
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1') {

        var ueberstand = Number(x3d.get('ueberstand')) + 1 + Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
        var r1 = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + ueberstand;

        var w1 = 0;
        var w2 = x3d.get('vWinkel');

        cords = this.getArc2dCords(w1 * Math.PI / 180, w2 * Math.PI / 180, r1);
      }

      if (typ === 'einschraubwinkel') {
        var r1 = 1.25;

        var w1 = 0;
        var w2 = -45;

        cords = this.getArc2dCords(w1 * Math.PI / 180, (w1 + w2) * Math.PI / 180, r1);
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.viewpointHelper', 'model.firstObject.arc2dPositionTriggern'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box' || item.get('typ') === 'platte') {

        if (typ === 'kontur') {
          values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (typ === 'lastenPfeil') {
          if (x3d.get('zweiSchaerflaechen')) {
            values = "0 1 -1 2 3 -1 4 5 -1";
          } else {
            values = "0 1 -1 2 3 -1";
          }
        }

        if ((name.substring(0, 8) === 'schraube' || item.get('typ') === "verbindungsmittel") && x3d.get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1' || typ === 'einschraubwinkel' && name === 'hauptTraeger1') {
        values = "0 1 -1 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('typ') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    zweiSchaerflaechen: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);

      if (this.get('name') === 'scherkraftPfeil' && x3d.get('zweiSchaerflaechen')) {
        value = true;
      }
      return value;
    }).property('model.firstObject.zweiSchaerflaechen'),

    emissivecolor: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('htbreiteAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('setztiefeAktiv') === true && name === 'hauptTraeger1' && typ === 'setztiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('einschraubwinkelAktiv') === true && name === 'hauptTraeger1' && typ === 'einschraubwinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'hauptTraeger1' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('axialkraftAktiv') === true && name === 'axialkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('scherkraftAktiv') === true && name === 'scherkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.axialkraftAktiv', 'model.firstObject.scherkraftAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.einschraubwinkelAktiv', 'model.firstObject.setztiefeAktiv'),

    getUrsprungMasskette: function getUrsprungMasskette(ursprung, richtung, verschiebung) {

      ursprung.x = ursprung.x + richtung.x * verschiebung;
      ursprung.y = ursprung.y + richtung.y * verschiebung;
      ursprung.z = ursprung.z + richtung.z * verschiebung;

      return ursprung;
    },

    getErgebnisKonturKoordinaten: function getErgebnisKonturKoordinaten() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var x0 = Number(item.get('punkt').objectAt(0).get('x')) / 10;
      var y0 = Number(item.get('punkt').objectAt(0).get('y')) / 10;
      var z0 = Number(item.get('punkt').objectAt(0).get('z')) / 10;
      var x1 = Number(item.get('punkt').objectAt(1).get('x')) / 10;
      var y1 = Number(item.get('punkt').objectAt(1).get('y')) / 10;
      var z1 = Number(item.get('punkt').objectAt(1).get('z')) / 10;
      var x2 = Number(item.get('punkt').objectAt(2).get('x')) / 10;
      var y2 = Number(item.get('punkt').objectAt(2).get('y')) / 10;
      var z2 = Number(item.get('punkt').objectAt(2).get('z')) / 10;
      var x3 = Number(item.get('punkt').objectAt(3).get('x')) / 10;
      var y3 = Number(item.get('punkt').objectAt(3).get('y')) / 10;
      var z3 = Number(item.get('punkt').objectAt(3).get('z')) / 10;

      var dicke = Number(item.get('platte').objectAt(0).get('thickness')) / 10;

      //hintere Ebene
      var p0 = x0 + ' ' + y0 + ' ' + z0;
      var p1 = x1 + ' ' + y1 + ' ' + z1;
      var p2 = x2 + ' ' + y2 + ' ' + z2;
      var p3 = x3 + ' ' + y3 + ' ' + z3;

      // fordere Ebene
      var p4 = x0 + ' ' + y0 + ' ' + (z0 + dicke);
      var p5 = x1 + ' ' + y1 + ' ' + (z1 + dicke);
      var p6 = x2 + ' ' + y2 + ' ' + (z2 + dicke);
      var p7 = x3 + ' ' + y3 + ' ' + (z3 + dicke);

      var p8 = 0;
      var p9 = 0;

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return cords;
    },

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      var deltaXht = 0;
      var deltaXnt = 0;
      var xMittelbauteil = 0;
      var xSeitenbauteil = 0;

      var ueberstand = Number(x3d.get('ueberstand'));

      var x1 = 0,
          x2 = 0;

      var schraubenlaenge = Number(x3d.get('bauteile').findBy('id', "schraubeStandard1").get('screwProperties').objectAt(0).get('length')) / 10;
      var kopfPos = schraubenlaenge * Math.sin(0.7854);

      switch (name) {
        case 'nebenTraeger':
          // x = 2.5*bauteile.findBy('id', 'queranschluss1').get('boxsizes').objectAt(0).get('x')/10;
          deltaXnt = Math.abs(x3d.get('xSeitenbauteil') / 10) + ueberstand;
          xMittelbauteil = Math.abs(x3d.get('xMittelbauteil') / 10);
          break;
        case 'hauptTraeger1':
          // deltaXht = Math.abs(x3d.get('xMittelbauteil')/10) + ueberstand;
          deltaXht = kopfPos + ueberstand;
          xSeitenbauteil = Math.abs(x3d.get('xSeitenbauteil') / 10);
          break;
        case 'hauptTraeger2':
          // deltaXht = Math.abs(x3d.get('xMittelbauteil')/10) + ueberstand;
          deltaXht = kopfPos + ueberstand;
          xSeitenbauteil = Math.abs(x3d.get('xSeitenbauteil') / 10);
          break;
      }

      var p0 = -deltaXnt - xSeitenbauteil + ' ' + 0 + ' ' + -z / 2;
      var p1 = -deltaXnt - xSeitenbauteil + ' ' + 0 + ' ' + z / 2;
      var p2 = deltaXht + xMittelbauteil + ' 0 ' + z / 2;
      var p3 = deltaXht + xMittelbauteil + ' 0 ' + -z / 2;
      var p4 = -deltaXnt - xSeitenbauteil + ' ' + y + ' ' + -z / 2;
      var p5 = -deltaXnt - xSeitenbauteil + ' ' + y + ' ' + z / 2;
      var p6 = deltaXht + xMittelbauteil + ' ' + y + ' ' + z / 2;
      var p7 = deltaXht + xMittelbauteil + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehwinkel) {
      var typ = this.get('typ');

      // var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor')+1;
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    delta: function delta(l, winkel) {
      var value = l * Math.cos(winkel);
      return value;
    },

    winkel: function winkel(x, y) {
      return Math.atan(x / y);
    },

    vektorenWinkel: function vektorenWinkel(v1, v2) {
      var zaehler = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
      // console.log('zaehler: '+zaehler);
      var n1 = Math.sqrt(Math.pow(v1.x, 2) + Math.pow(v1.y, 2) + Math.pow(v1.z, 2));
      // console.log('n1: '+n1);
      var n2 = Math.sqrt(Math.pow(v2.x, 2) + Math.pow(v2.y, 2) + Math.pow(v2.z, 2));
      // console.log('n2: '+n2);
      var nenner = n1 * n2;
      // console.log('nenner: '+nenner);
      // console.log('zaehler/nenner: '+zaehler/nenner);
      var alpha = Math.acos((zaehler / nenner).toFixed(6));
      return alpha;
    },

    differenzVektor: function differenzVektor(v2, v1) {
      var vResult = new Object();
      vResult.x = v2.x - v1.x;
      vResult.y = v2.y - v1.y;
      vResult.z = v2.z - v1.z;
      return vResult;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },

    getArc2dCords: function getArc2dCords(w1, w2, r) {
      var cords = '0 0 0 0 0 0 0 0 0 0 0 0';

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;

      var l_endLinien = 0.5;

      var p1 = new Object();
      var p2 = new Object();
      var p3 = new Object();
      var p4 = new Object();

      p1.x = r * Math.cos(w1);
      p1.y = r * Math.sin(w1);
      p1.z = 0;
      p2.x = (r + l_endLinien) * Math.cos(w1);
      p2.y = (r + l_endLinien) * Math.sin(w1);
      p2.z = 0;
      p3.x = r * Math.cos(w2);
      p3.y = r * Math.sin(w2);
      p3.z = 0;
      p4.x = (r + l_endLinien) * Math.cos(w2);
      p4.y = (r + l_endLinien) * Math.sin(w2);
      p4.z = 0;

      cords = p1.x + ' ' + p1.y + ' ' + p1.z + ' ' + p2.x + ' ' + p2.y + ' ' + p2.z + ' ' + p3.x + ' ' + p3.y + ' ' + p3.z + ' ' + p4.x + ' ' + p4.y + ' ' + p4.z;

      return cords;
    },

    getFaserrichtungsPfeile: function getFaserrichtungsPfeile(item) {

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var name = this.get('name');

      var delta = 0.15;

      var ueberstand = Number(x3d.get('ueberstand'));

      var x = 0,
          y = 0,
          z = 0;

      var winkel = 0;

      if (item.get('typ') === 'box') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
        y = 0.15;
        z = item.get('boxsizes').objectAt(0).get('z') / 20;

        var xSeitenbauteil = Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
        var xMittelbauteil = Math.abs(Number(x3d.get('xMittelbauteil'))) / 10;

        if (name === 'hauptTraeger1' || name === 'hauptTraeger2') {
          // x = -x/2 - ueberstand + delta;
          x = xSeitenbauteil;
        } else {
          x = xSeitenbauteil + ueberstand;
        }
      } else if (item.get('typ') === 'platte') {

        x = -Number(item.get('punkt').objectAt(1).get('x')) / 10;
        y = Number(item.get('punkt').objectAt(1).get('y')) / 10 + 0.15;
        z = Number(item.get('punkt').objectAt(1).get('z')) / 10 + item.get('platte').objectAt(0).get('thickness') / 10;

        if (name.substring(0, 21) === 'verstaerkungdiagonale' || name.substring(0, 13) === 'seitenbauteil') {
          winkel = x3d.get('vWinkel') * Math.PI / 180;
        }
      }

      var cords = -x + this.deltaX(0.15 + delta, delta * 2, winkel) + ' ' + (y + this.deltaY(0.15 + delta, delta * 2, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(delta, delta, winkel)) + ' ' + (y + this.deltaY(delta, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(1, delta, winkel)) + ' ' + (y + this.deltaY(1, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(0.85, 0, winkel)) + ' ' + (y + this.deltaY(0.85, 0, winkel)) + ' ' + z;

      return cords;
    },

    deltaX: function deltaX(x, y, winkel) {
      var erg = x * Math.cos(winkel) - y * Math.sin(winkel);
      return erg;
    },

    deltaY: function deltaY(x, y, winkel) {
      var erg = x * Math.sin(winkel) + y * Math.cos(winkel);
      return erg;
    },

    getMittelwert: function getMittelwert(a, b) {
      return (a + b) / 2;
    },

    scherkraftXWert: function scherkraftXWert(yGurt) {

      var ergebnis = 0;

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var vWinkel = x3d.get('vWinkel');

      var P1 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(0);
      var P2 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 1);
      var P3 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 2);

      var x1 = P1.get('x');
      var y1 = P1.get('y');
      var x2 = P2.get('x');
      var y2 = P2.get('y');
      var x3 = P3.get('x');
      var y3 = P3.get('y');

      if (Number(vWinkel) === 0) {
        var Pmittelbauteil4 = bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(bauteile.findBy('id', 'mittelbauteil').get('punkt').length - 1);
        var xmb4 = Pmittelbauteil4.get('x');
        ergebnis = x1 + (xmb4 - x1) / 2;
      } else if (Number(vWinkel) === 90) {
        ergebnis = x3 + (x2 - x3) / 2;
      } else {
        var dx = (x2 - x3) / 2;
        var dy = (y2 - y3) / 2;

        var m = (y2 - y1) / (x2 - x1);

        var n = y3 + dy - m * (x3 + dx);

        ergebnis = (yGurt * 10 - n) / m;
      }

      return ergebnis;
    },

    postionDurchSkalierung: function postionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var value = 0.2 * skalierungsfaktor;
      return value;
    },

    getVektorKoordinaten: function getVektorKoordinaten(punkt1, punkt2, r1, r2, ebene) {

      var dr = 0.2 * (ebene - 1);

      var b05 = 0.05;
      var b1 = 0.1;
      var b5 = 0.5;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6;

      var verschiebung = b5 * (ebene - 1) + dr;

      var p0 = Number(punkt1.x) + verschiebung * Number(r1.x) + ' ' + (Number(punkt1.y) + verschiebung * Number(r1.y)) + ' ' + (Number(punkt1.z) + verschiebung * Number(r1.z));
      var p1 = Number(punkt2.x) + verschiebung * Number(r2.x) + ' ' + (Number(punkt2.y) + verschiebung * Number(r2.y)) + ' ' + (Number(punkt2.z) + verschiebung * Number(r2.z));

      var p2 = Number(punkt2.x) + (b6 + verschiebung) * Number(r2.x) + ' ' + (Number(punkt2.y) + (b6 + verschiebung) * Number(r2.y)) + ' ' + (Number(punkt2.z) + (b6 + verschiebung) * Number(r2.z));
      var p3 = Number(punkt1.x) + (b6 + verschiebung) * Number(r1.x) + ' ' + (Number(punkt1.y) + (b6 + verschiebung) * Number(r1.y)) + ' ' + (Number(punkt1.z) + (b6 + verschiebung) * Number(r1.z));

      var p4 = this.getXYZ(punkt1, punkt2, r1, r2, b5 + verschiebung, b1);

      var p5 = this.getXYZ(punkt2, punkt1, r2, r1, b5 + verschiebung, b1);

      var p6 = this.getXYZ(punkt2, punkt1, r2, r1, b45 + verschiebung, b05);
      var p8 = this.getXYZ(punkt1, punkt2, r1, r2, b45 + verschiebung, -b05);

      var p7 = this.getXYZ(punkt2, punkt1, r2, r1, b55 + verschiebung, -b05);
      var p9 = this.getXYZ(punkt1, punkt2, r1, r2, b55 + verschiebung, b05);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    vektorLaenge: function vektorLaenge(x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },

    getXYZ: function getXYZ(punkt1, punkt2, r1, r2, richtungsAbstand, seitenAbstand) {

      var p1_2 = { x: Number(punkt1.x) + richtungsAbstand * Number(r1.x), y: Number(punkt1.y) + richtungsAbstand * Number(r1.y), z: Number(punkt1.z) + richtungsAbstand * Number(r1.z) };
      var p2_2 = { x: Number(punkt2.x) + richtungsAbstand * Number(r2.x), y: Number(punkt2.y) + richtungsAbstand * Number(r2.y), z: Number(punkt2.z) + richtungsAbstand * Number(r2.z) };

      var r3 = { x: p2_2.x - p1_2.x, y: p2_2.y - p1_2.y, z: p2_2.z - p1_2.z };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);
      var r3Einheitsvektor = { x: r3.x / lr3, y: r3.y / lr3, z: r3.z / lr3 };

      var x = p1_2.x + r3Einheitsvektor.x * (lr3 + seitenAbstand);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 + seitenAbstand);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 + seitenAbstand);

      return x + ' ' + y + ' ' + z;
    }

  });

  exports['default'] = Transform;

});