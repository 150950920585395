define('m07/models/geometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var geometrie = DS['default'].Model.extend({

		ShearPlaneMultiplicator: DS['default'].attr('string'),
		ConnectionType: DS['default'].attr('string'),
		Setztiefe: DS['default'].attr('string'),
		Predrilled: DS['default'].attr('boolean'),
		FastenerDepth: DS['default'].attr('string'),

		scherflaechenVM: DS['default'].attr('string'),
		scherflaechenA: DS['default'].attr('string'),
		schichtfolge: DS['default'].attr('string')
	});

	geometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			ShearPlaneMultiplicator: "1",
			ConnectionType: "HH",
			Setztiefe: "0.0",
			Predrilled: false,
			FastenerDepth: "0.0",

			scherflaechenVM: 1,
			scherflaechenA: 1,
			schichtfolge: 1
		}]
	});

	exports['default'] = geometrie;

});