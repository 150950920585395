define('m07/components/indexedfacese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'indexedFaceSet',
    layoutName: 'indexedfacese-t',
    attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var x0 = Number(item.get('punkt').objectAt(0).get('x')) / 10;
      var y0 = Number(item.get('punkt').objectAt(0).get('y')) / 10;
      var z0 = Number(item.get('punkt').objectAt(0).get('z')) / 10;
      var x1 = Number(item.get('punkt').objectAt(1).get('x')) / 10;
      var y1 = Number(item.get('punkt').objectAt(1).get('y')) / 10;
      var z1 = Number(item.get('punkt').objectAt(1).get('z')) / 10;
      var x2 = Number(item.get('punkt').objectAt(2).get('x')) / 10;
      var y2 = Number(item.get('punkt').objectAt(2).get('y')) / 10;
      var z2 = Number(item.get('punkt').objectAt(2).get('z')) / 10;
      var x3 = Number(item.get('punkt').objectAt(3).get('x')) / 10;
      var y3 = Number(item.get('punkt').objectAt(3).get('y')) / 10;
      var z3 = Number(item.get('punkt').objectAt(3).get('z')) / 10;

      var dicke = Number(item.get('platte').objectAt(0).get('thickness')) / 10;

      //hintere Ebene
      var p0 = x0 + ' ' + y0 + ' ' + z0;
      var p1 = x1 + ' ' + y1 + ' ' + z1;
      var p2 = x2 + ' ' + y2 + ' ' + z2;
      var p3 = x3 + ' ' + y3 + ' ' + z3;

      // fordere Ebene
      var p4 = x0 + ' ' + y0 + ' ' + (z0 + dicke);
      var p5 = x1 + ' ' + y1 + ' ' + (z1 + dicke);
      var p6 = x2 + ' ' + y2 + ' ' + (z2 + dicke);
      var p7 = x3 + ' ' + y3 + ' ' + (z3 + dicke);

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    coordIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    colorindex: (function () {

      var values = "0 0 0 0 0 0 0";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);
      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt')

  });

  exports['default'] = Transform;

});