define('m07/models/lasteinwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var lasteinwirkung = DS['default'].Model.extend({

		NKL_ID: DS['default'].attr('string'),
		NKL_Text: DS['default'].attr('string'),
		KLED_ID: DS['default'].attr('string'),
		LED_Text: DS['default'].attr('string'),
		V_Ed: DS['default'].attr('string'),
		Reibbeiwert: DS['default'].attr('string'),
		ALFA_Force_GRAD: DS['default'].attr('string'),
		N_Ed: DS['default'].attr('string'),
		AngleEndGrainGRAD: DS['default'].attr('string')

	});

	lasteinwirkung.reopenClass({
		FIXTURES: [{
			id: 1,
			LED_Text: "ständig",
			NKL_ID: 1,
			KLED_ID: 1,
			NKL_Text: "Nutzungsklasse 1",
			V_Ed: "0.0",
			Reibbeiwert: "0.25",
			ALFA_Force_GRAD: "0",
			N_Ed: "0",
			AngleEndGrainGRAD: "90"

		}]
	});

	exports['default'] = lasteinwirkung;

});