define('m07/models/ansichtsvektoren', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    x: DS['default'].attr('number'),
    y: DS['default'].attr('number'),
    z: DS['default'].attr('number')
  });

});