define('m07/components/spheresegmen-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var spheresegment = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'spheresegmen-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,0.9,0.55",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var x = 0;
      var y = 0;
      var z = 0;

      var richtung = 1;
      var schraubendurchmesser = 0;
      var winkel = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        var r = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) * 0.15;

        x = item.get('punkt').objectAt(0).get('x') / 10 - r * 2 / 3;
        y = item.get('punkt').objectAt(0).get('y') / 10;
        // z = item.get('punkt').objectAt(0).get('z')/10 + Number(item.get('richtungsvektor').objectAt(0).get('z')) * Number(item.get('screwProperties').objectAt(0).get('length'))/10;

        var seitenbauteil = bauteile.findBy('id', "seitenbauteil0");
        var thickness = Number(seitenbauteil.get('platte').objectAt(0).get('thickness')) / 10;
        var z0 = Number(seitenbauteil.get('punkt').objectAt(0).get('z')) / 10;
        z = z0 + thickness;
        richtung = Math.abs(Number(item.get('richtungsvektor').objectAt(0).get('z'))) / Number(item.get('richtungsvektor').objectAt(0).get('z'));

        winkel = this.getWinkel({ x: Number(item.get('richtungsvektor').objectAt(0).get('x')), y: 0, z: Number(item.get('richtungsvektor').objectAt(0).get('x')) }, { x: 0, y: 0, z: 1 });
      } else {
        x = item.get('translations').objectAt(0).get('x') / 10 - 0.1;
        y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
        z = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;
        richtung = Number(item.get('rotations').objectAt(0).get('z')) / Math.abs(Number(item.get('rotations').objectAt(0).get('z')));
        winkel = Number(item.get('rotations').objectAt(0).get('y'));
      }

      var schraubenlaenge = Number(item.get('screwProperties').objectAt(0).get('length')) / 10;

      schraubendurchmesser = Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('diameter')) / 10;

      x = x + Math.cos(winkel) * schraubenlaenge;

      var values = x + ' ' + y + ' ' + richtung * z;

      return values;
    }).property(''),

    rotation: (function () {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var winkel = Number(item.get('rotations').objectAt(0).get('z'));

      if (x3d.get('ergebnisGeladen')) {
        var x = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        var y = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        var z = Number(item.get('richtungsvektor').objectAt(0).get('z'));

        winkel = Number(item.get('richtungsvektor').objectAt(0).get('z')) / Math.abs(Number(item.get('richtungsvektor').objectAt(0).get('z'))) * this.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 1, z: 0 });
      } else {
        winkel = Number(item.get('rotations').objectAt(0).get('z'));
      }

      var values = '1 0 0 ' + winkel;

      return values;
    }).property(''),

    radius: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var r = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) * 0.15;

      return r;
    }).property(''),

    emissivecolor: (function () {
      var x3d = this.get('model').objectAt(0);
      var name = this.get('name');

      var emsvcolor = "0.5 0.5 0.75";

      return emsvcolor;
    }).property(''),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    }

  });

  exports['default'] = spheresegment;

});